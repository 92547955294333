import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
import 'bootstrap';
import 'popper.js/dist/popper.js';
import {WOW} from 'wowjs'
window.moment = require('moment');
window.jQuery = $;
window.WOW = WOW;
window.$ = $;
import 'owl.carousel'

import 'slick-carousel';
import 'select2';                       // globally assign select2 fn to $ element

$(() => {
  $('.select2-enable').select2();
});

require("./custom.js");
require("./mobile_nav.js");
// require('datatables.net-bs4')